import * as React from 'react';
import GlobalHeader from './global-header';

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  const header = <GlobalHeader title={title} />

  return (
    <>
      <header>{header}</header>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <div className="content-wrapper">
          <main>{children}</main>
          <footer>
            © {new Date().getFullYear()} Daniela's Healthy Living
          </footer>
        </div>
      </div>
    </>
  );
};

export default Layout;
