import * as React from 'react';
import { Link } from 'gatsby';


const GlobalHeader = ({ title }) => {
	return (
		<div className="global-header">
			<h1>
				<Link to="/">{title}</Link>
			</h1>
		</div>

	);
};


export default GlobalHeader;